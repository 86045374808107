function PromptIt(array)
{
    this.message = array.message
    this.placeholder = array.placeholder || 'Type here'
    this.confirm = array.confirm || 'Confirm'
    this.cancel = array.cancel || 'Cancel'
    this.onConfirm = array.onConfirm || null
    this.onCancel = array.onCancel || null

    this.pptBuildStructure = function()
    {
        // Creating layer.
        this.pptLayer = document.createElement('div')
        this.pptLayer.classList.add('ppt-layer')

        // Creating container.
        this.pptBoxContainer = document.createElement('div')
        this.pptBoxContainer.classList.add('ppt-box-container')

        // Creating box.
        this.pptBox = document.createElement('div')
        this.pptBox.classList.add('ppt-box')

        // Creating message container.
        this.pptMessage = document.createElement('span')
        this.pptMessage.classList.add('ppt-message')

        // Creating fieldset.
        this.pptFieldset = document.createElement('div')
        this.pptFieldset.classList.add('ppt-fieldset')

        // Creating input.
        this.pptInput = document.createElement('input')
        this.pptInput.classList.add('ppt-input')
        this.pptInput.setAttribute('type', 'text')
        this.pptInput.setAttribute('autocomplete', 'off')
        this.pptInput.setAttribute('placeholder', this.placeholder)

        // Creating buttons.
        this.pptBtns = document.createElement('ul')
        this.pptBtns.classList.add('ppt-btns')
        // Confirm button.
        this.pptBtnConfirm = document.createElement('li')
        this.pptBtnConfirm.classList.add(
            'ppt-btn',
            'ppt-btn-confirm'
        )
        // Cancel button.
        this.pptBtnCancel = document.createElement('li')
        this.pptBtnCancel.classList.add(
            'ppt-btn',
            'ppt-btn-cancel'
        )

        // Appending new instance to the body.
        this.pptAppendStructure()
    }

    this.pptAppendStructure = function()
    {
        // Appending buttons labels.
        this.pptBtnConfirm.appendChild(document.createTextNode(this.confirm))
        this.pptBtnCancel.appendChild(document.createTextNode(this.cancel))

        // Appending buttons.
        this.pptBtns.appendChild(this.pptBtnCancel)
        this.pptBtns.appendChild(this.pptBtnConfirm)

        // Appending fieldset elements.
        this.pptFieldset.appendChild(this.pptInput)

        // Appending message to its container.
        // this.pptMessage.appendChild(document.createTextNode(this.message))
        this.pptMessage.innerHTML = this.message

        // Appending message and buttons to the box.
        this.pptBox.appendChild(this.pptMessage)
        this.pptBox.appendChild(this.pptFieldset)
        this.pptBox.appendChild(this.pptBtns)

        // Appending box to its container.
        this.pptBoxContainer.appendChild(this.pptBox)

        // Appending box container to the layer.
        this.pptLayer.appendChild(this.pptBoxContainer)

        // Appending layer to the body.
        document.body.appendChild(this.pptLayer)

        // Focusing on the input.
        this.pptInput.focus()

        // Showing up new instance.
        this.pptShowUpStructure()
    }

    this.pptShowUpStructure = function()
    {
        var self = this

        setTimeout(function() {
            self.pptLayer.classList.add('ppt-visible')
            self.pptBoxContainer.classList.add('ppt-visible')
        }, 50)

        // Buttons click event.
        function confirmed()
        {
            if (self.pptInput.value.trim()) {
                document.removeEventListener('keyup', isEnterOrEscapePressed)

                self.pptHideStructure()

                setTimeout(function() {
                    self.pptLayer.remove()

                    if (typeof self.onConfirm === 'function') {
                        self.onConfirm(self.pptInput.value.trim())
                    }
                }, self.getElementTransitionDuration(self.pptBoxContainer))
            } else {
                self.pptInput.value = ''
                // self.pptInput.setAttribute('placeholder', 'Required field')
                self.pptInput.focus()
            }
        }
        self.pptBtnConfirm.addEventListener('click', confirmed)

        function canceled()
        {
            document.removeEventListener('keyup', isEnterOrEscapePressed)

            self.pptHideStructure()

            setTimeout(function() {
                self.pptLayer.remove()

                if (typeof self.onCancel === 'function') {
                    self.onCancel()
                }
            }, self.getElementTransitionDuration(self.pptBoxContainer))
        }
        self.pptBtnCancel.addEventListener('click', canceled)

        function isEnterOrEscapePressed(e)
        {
            if (e.keyCode == 27) {
                return canceled()
            }

            if (e.keyCode == 13) {
                return confirmed()
            }
        }
        document.addEventListener('keyup', isEnterOrEscapePressed)
    }

    this.pptHideStructure = function()
    {
        this.pptLayer.classList.remove('ppt-visible')
        this.pptBoxContainer.classList.remove('ppt-visible')
    }

    this.getElementTransitionDuration = function(element)
    {
        var transitionDuration = getComputedStyle(element)['transitionDuration']
        var transitionDurationValue = parseFloat(transitionDuration, 10)

        // Converting to milliseconds.
        if (! transitionDuration.match(/ms/)) {
            transitionDurationValue *= 1000
        }

        // Add extra time to prevent loading issues.
        transitionDurationValue += 50

        return transitionDurationValue
    }

    // Building a new instance.
    this.pptBuildStructure()
}
